<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#A4E174;"
      d="M240.977,25.206v436.669c-3.27,0.145-6.576,0.217-9.894,0.217c-3.318,0-6.624-0.072-9.894-0.217
	V25.206c3.282-0.181,6.576-0.278,9.894-0.278C234.401,24.928,237.695,25.025,240.977,25.206z"
    />
    <path
      style="fill:#96D5F6;"
      d="M462.153,256.006c0,3.053-0.06,6.105-0.181,9.134c-2.112,54.128-22.841,103.49-55.974,141.848
	c-0.639,0.748-1.291,1.496-1.967,2.232c-3.427,3.897-7.01,7.674-10.727,11.318c-39.528,38.973-93.041,63.817-152.321,66.315
	c-3.27,0.145-6.576,0.217-9.894,0.217c-3.318,0-6.624-0.072-9.906-0.217c-59.268-2.498-112.793-27.342-152.321-66.327
	c-4.416-4.32-8.651-8.844-12.706-13.538C23.022,368.63,2.293,319.268,0.181,265.14C0.06,262.111,0,259.059,0,256.006
	c0-3.065,0.06-6.105,0.181-9.134c2.112-54.14,22.841-103.502,55.974-141.86c4.054-4.682,8.277-9.206,12.693-13.55
	c39.528-38.973,93.041-63.829,152.334-66.327c3.282-0.133,6.588-0.217,9.906-0.217c3.318,0,6.624,0.084,9.894,0.217
	c59.28,2.498,112.793,27.354,152.321,66.327c4.416,4.344,8.651,8.869,12.693,13.55c26.232,30.37,44.693,67.642,52.282,108.715
	c2.003,10.811,3.246,21.876,3.692,33.145C462.093,249.901,462.153,252.941,462.153,256.006z"
    />
    <path
      style="fill:#A0DBFD;"
      d="M430.872,255.993c0,127.622-89.445,231.076-199.789,231.076S31.293,383.615,31.293,255.993
	c0-123.773,84.148-224.826,189.895-230.787c3.282-0.181,6.576-0.278,9.894-0.278c3.318,0,6.612,0.097,9.894,0.278
	C346.723,31.167,430.872,132.22,430.872,255.993z"
    />
    <path
      style="fill:#96D5F6;"
      d="M462.153,256.006c0,3.053-0.06,6.105-0.181,9.134c-2.112,54.128-22.841,103.49-55.974,141.848
	c-0.639,0.748-1.291,1.496-1.967,2.232c-6.202,1.074-12.585,1.629-19.1,1.629c-9.906,0-19.511-1.279-28.657-3.704
	c-48.083-12.657-83.545-56.445-83.545-108.51c0-61.971,50.231-112.202,112.202-112.202c12.802,0,25.109,2.148,36.572,6.105
	c13.647,4.694,26.099,11.957,36.777,21.188c2.003,10.811,3.246,21.876,3.692,33.145
	C462.093,249.901,462.153,252.941,462.153,256.006z"
    />
    <g>
      <path
        style="fill:#81C6E8;"
        d="M357.449,382.271c15.776-37.197,24.25-80.754,24.25-126.272c0-45.515-8.473-89.071-24.247-126.266
		c17.128-6.599,33.026-14.604,47.34-23.926l1.203-0.792c-4.035-4.69-8.268-9.212-12.684-13.551
		c-13.059,8.339-27.764,15.563-43.666,21.563c-3.331-6.502-6.897-12.773-10.709-18.765c-26.159-41.123-60.639-65.247-97.953-68.88
		v-0.245c-1.933-0.081-3.879-0.133-5.83-0.168c-1.355-0.054-2.711-0.084-4.072-0.084c-1.362,0-2.72,0.031-4.075,0.084
		c-1.946,0.035-3.889,0.087-5.817,0.168v0.244c-37.318,3.631-71.801,27.754-97.963,68.881c-3.812,5.992-7.377,12.261-10.707,18.764
		c-15.907-6.002-30.614-13.226-43.677-21.563c-4.416,4.34-8.633,8.861-12.684,13.551l1.203,0.792
		c14.318,9.321,30.218,17.326,47.347,23.925c-14.717,34.701-23.068,74.938-24.124,117.137h-80.4
		c-0.122,3.03-0.182,6.075-0.182,9.136c0,3.06,0.062,6.105,0.182,9.136h80.401c1.057,42.197,9.409,82.434,24.125,117.132
		c-17.13,6.601-33.031,14.607-47.349,23.928l-1.203,0.792c4.051,4.69,8.283,9.212,12.699,13.537
		c13.06-8.332,27.763-15.552,43.661-21.551c3.33,6.5,6.895,12.768,10.705,18.758c26.162,41.127,60.645,65.252,97.963,68.883v0.239
		c1.89,0.08,3.791,0.132,5.698,0.167c1.395,0.057,2.792,0.092,4.194,0.092c1.401,0,2.798-0.035,4.192-0.092
		c1.911-0.035,3.816-0.087,5.711-0.167v-0.239c37.314-3.633,71.794-27.757,97.953-68.881c3.81-5.991,7.376-12.259,10.706-18.76
		c15.904,6.003,30.609,13.229,43.668,21.568c4.416-4.34,8.649-8.863,12.684-13.551l-1.203-0.792
		C390.476,396.878,374.578,388.872,357.449,382.271z M361.904,255.999c0,43.418-8.126,84.77-23.21,119.769
		c-30.029-9.342-63.145-14.657-97.712-15.51V265.14h91.498c5.046,0,9.136-4.09,9.136-9.136s-4.09-9.136-9.136-9.136h-91.498v-95.133
		c34.568-0.852,67.684-6.163,97.713-15.501C353.778,171.231,361.904,212.582,361.904,255.999z M322.233,104.886
		c2.968,4.667,5.766,9.533,8.421,14.548c-27.745,8.329-58.304,13.175-89.673,14.02V45.289
		C271.394,49.013,299.94,69.841,322.233,104.886z M139.926,104.886c22.294-35.049,50.845-55.877,81.261-59.599v88.166
		c-31.366-0.846-61.929-5.693-89.681-14.023C134.161,114.417,136.958,109.552,139.926,104.886z M123.464,136.231
		c30.034,9.339,63.152,14.651,97.722,15.502v95.133H100.397C101.452,206.794,109.442,168.766,123.464,136.231z M100.397,265.14
		h120.791v95.118c-34.569,0.853-67.688,6.168-97.72,15.511C109.445,343.236,101.454,305.211,100.397,265.14z M139.926,407.111
		c-2.968-4.666-5.764-9.53-8.418-14.542c27.751-8.334,58.311-13.185,89.68-14.032v88.171
		C190.77,462.988,162.22,442.161,139.926,407.111z M322.233,407.111c-22.293,35.045-50.839,55.872-81.251,59.596v-88.17
		c31.369,0.846,61.926,5.695,89.671,14.028C327.998,397.58,325.201,402.445,322.233,407.111z"
      />
      <path
        style="fill:#81C6E8;"
        d="M461.967,246.867h-49.562c-5.046,0-9.136,4.09-9.136,9.136c0,5.046,4.09,9.136,9.136,9.136h49.562
		c0.122-3.03,0.182-6.075,0.182-9.136C462.15,252.944,462.089,249.898,461.967,246.867z"
      />
    </g>
    <path
      style="fill:#FED159;"
      d="M512,322.105c0,61.971-50.243,112.214-112.214,112.214c-9.906,0-19.511-1.279-28.657-3.704
	c-48.083-12.657-83.545-56.445-83.545-108.51c0-61.971,50.231-112.202,112.202-112.202c12.802,0,25.109,2.148,36.572,6.105
	C480.375,231.163,512,272.936,512,322.105z"
    />
    <path
      style="fill:#FFE4A9;"
      d="M512,322.105c0,61.971-50.243,112.214-112.214,112.214c-9.906,0-19.511-1.279-28.657-3.704
	l65.229-214.606C480.375,231.163,512,272.936,512,322.105z"
    />
    <polygon
      style="fill:#EAB64E;"
      points="442.038,290.692 442.038,272.783 408.747,272.783 408.747,249.712 390.838,249.712 
	390.838,272.783 357.546,272.783 357.546,331.066 424.128,331.066 424.128,353.531 357.546,353.531 357.546,371.44 390.838,371.44 
	390.838,394.511 408.747,394.511 408.747,371.44 442.038,371.44 442.038,313.157 375.456,313.157 375.456,290.692 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
